// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
// could not get Chartkick to work here. so instead did it inline.
// import "channels"
require("channels")

Rails.start()
Turbolinks.start()
ActiveStorage.start()
history.scrollRestoration = 'manual';

global.$ = require("jquery")

// require("jquery-ui") //GAMMACBE was unable to figure this out, ended up manually loading jQuery & jQuery-UI in the view.
